import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="linkedin-slide"
export default class extends Controller {
  connect() {}
}

const posts = document.querySelectorAll(".container-slide-linkedin");
const nbPosts = posts.length;
const suivant = document.querySelector(".right-arrow");
const precedent = document.querySelector(".left-arrow");
const points = document.querySelectorAll(".slider-dot-linkedin");

let count = 0;

function avisSuivant() {
  posts[count].classList.remove("active");
  points.forEach((point) => point.classList.remove("active"));

  if (count < nbPosts - 1) {
    count++;
    // } else {
    //   count = 0;
  }

  posts[count].classList.add("active");
  points[count].classList.add("active");
}

if (suivant) {
  suivant.addEventListener(
    "click",
    () => {
      avisSuivant();
    },
    false
  );
}

function avisPrecedent() {
  posts[count].classList.remove("active");
  points.forEach((point) => point.classList.remove("active"));

  if (count > 0) {
    count--;
    // } else {
    //   count = nbPosts - 1;
  }

  posts[count].classList.add("active");
  points[count].classList.add("active");
}

if (precedent) {
  precedent.addEventListener(
    "click",
    function () {
      avisPrecedent();
    },
    false
  );
}

if (points) {
  points.forEach((point, i) => {
    point.addEventListener("click", () => {
      posts.forEach((post) => post.classList.remove("active"));
      points.forEach((point) => point.classList.remove("active"));

      count = i;

      posts[count].classList.add("active");
      points[count].classList.add("active");
    });
  });
}

//----------------- read more & read less -----------------//

const readMoreBtns = document.querySelectorAll(".read-more-btn");

if (readMoreBtns) {
  readMoreBtns.forEach((readMoreBtn) => {
    readMoreBtn.addEventListener("click", function () {
      const container = this.closest(".profile-avis");
      container.classList.toggle("active");
    });
  });
}
// let resizeTimer;
// window.addEventListener("resize", () => {
//   document.body.classList.add("resize-animation-stopper");
//   clearTimeout(resizeTimer);
//   resizeTimer = setTimeout(() => {
//     document.   body.classList.remove("resize-animation-stopper");
//   }, 400);
// });

// //----------------- Slider de XP -----------------//

// const Xps = document.querySelectorAll(".container-slide-xp");
// const nbXP = Xps.length;

// const next = document.querySelector(".right-arrow-xp");
// const previous = document.querySelector(".left-arrow-xp");

// const dots = document.querySelectorAll(".slider-dot");
// const nbDots = dots.length;

// let counter = 0;

// let touchstartX = 0;
// let touchendX = 0;
// const minSwipeDistance = 10; // Seuil minimal de distance de glissement (en pixels)

// function xpSuivant() {
//   Xps.forEach((xp) => xp.classList.remove("active"));
//   dots.forEach((dot) => dot.classList.remove("active"));

//   if (counter < nbXP - 1) {
//     counter++;
//   } else {
//     counter = 0;
//   }

//   Xps[counter].classList.add("active");
//   dots[counter].classList.add("active");

//   console.log(counter);
// }

// if (next) {
//   next.addEventListener(
//     "click",
//     function () {
//       xpSuivant();
//       1000;
//     },
//     false
//   );
// }

// function xpPrecedent() {
//   Xps.forEach((xp) => xp.classList.remove("active"));
//   dots.forEach((dot) => dot.classList.remove("active"));

//   if (counter > 0) {
//     counter--;
//   } else {
//     counter = nbXP - 1;
//   }

//   Xps[counter].classList.add("active");
//   dots[counter].classList.add("active");
// }
// if (previous) {
//   previous.addEventListener(
//     "click",
//     function () {
//       xpPrecedent();
//       1000;
//     },
//     false
//   );
// }

// function handleGesture() {
//   const swipeDistance = touchendX - touchstartX;

//   if (swipeDistance < -minSwipeDistance) {
//     xpSuivant();
//   }

//   if (swipeDistance > minSwipeDistance) {
//     xpPrecedent();
//   }
// }

// if (dots) {
//   dots.forEach((dot, i) => {
//     dot.addEventListener("click", () => {
//       Xps.forEach((Xp) => Xp.classList.remove("active"));
//       dots.forEach((dot) => dot.classList.remove("active"));

//       counter = i;

//       Xps[counter].classList.add("active");
//       dots[counter].classList.add("active");
//     });
//   });
// }

// document.addEventListener("touchstart", (event) => {
//   touchstartX = event.changedTouches[0].screenX;
// });

// document.addEventListener("touchmove", (event) => {
//   event.preventDefault();
// });

// document.addEventListener("touchend", (event) => {
//   touchendX = event.changedTouches[0].screenX;
//   handleGesture();
// });
let resizeTimer;
window.addEventListener("resize", () => {
  document.body.classList.add("resize-animation-stopper");
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(() => {
    document.body.classList.remove("resize-animation-stopper");
  }, 400);
});
