import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="navbar-active"
export default class extends Controller {
  connect() {}
}

//------------------------------------------------------------------------------------------------

// animation for the hamburger menu

const buttonThree = document.querySelector(".button-three");
const menu = document.querySelector(".navbar_links");

buttonThree.addEventListener("click", () => {
  menu.classList.toggle("mobile-menu");

  const isOpened = buttonThree.getAttribute("aria-expanded");

  if (isOpened === "false") {
    buttonThree.setAttribute("aria-expanded", "true");
  } else {
    buttonThree.setAttribute("aria-expanded", "false");
  }
});

// close menu when clicking on a link
const navLinks = document.querySelectorAll(".navbar_links a");
navLinks.forEach((link) => {
  link.addEventListener("click", () => {
    menu.classList.remove("mobile-menu");
    buttonThree.setAttribute("aria-expanded", "false");
  });
});

//------------------------------------------------------------------------------------------------

// when scrolling, navbar changed its color/transparency

// const navLink = document.querySelectorAll("nav a");
// const sections = document.querySelectorAll("section");

// const sectionObserver = new IntersectionObserver(function (
//   entries,
//   sectionObserver
// ) {
//   entries.forEach((entry) => {
//     console.log(entry.target);
//     if (entry.isIntersecting) {
//       const sectionName = entry.target.getAttribute("data-section-name");
//       navLink.forEach((link) => {
//         if (link.getAttribute("href") === `#${sectionName}`) {
//           link.classList.add("active");
//         } else {
//           link.classList.remove("active");
//         }
//       });
//     }
//   });
// });

// sections.forEach((section) => {
//   sectionObserver.observe(section);
// });

//------------------------------------------------------------------------------------------------
// avoid animation on resize

let resizeTimer;
window.addEventListener("resize", () => {
  document.body.classList.add("resize-animation-stopper");
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(() => {
    document.body.classList.remove("resize-animation-stopper");
  }, 400);
});
