import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="scroll"
export default class extends Controller {
  connect() {}
}

//------------------------------------------------------------------------------------------------

//scrolling snap to section + class active according to section

$(document).ready(function () {
  screenCheck();
});

$(window).on("resize", function () {
  screenCheck();
});

function applyScroll() {
  $.scrollify({
    section: ".scroll",
    sectionName: "section-name",
    interstitialSection: "",
    easing: "easeOutExpo",
    scrollSpeed: 500,
    offset: 0,
    scrollbars: true,
    setHeights: true,
    overflowScroll: true,
    updateHash: false,
    touchScroll: true,
    before: function (index) {
      anchors.forEach((a) => {
        a.classList.remove("active");
      });
      anchors[index + 1].classList.add("active");
    },
  });
}
function disapplyScroll() {
  $.scrollify({
    section: [".home", ".cv", ".experiences", ".contact"],
    sectionName: "section-name",
    interstitialSection: "",
    // standardScrollElements: "section",
    easing: "easeOutExpo",
    scrollSpeed: 1000,
    offset: 0,
    scrollbars: true,
    setHeights: true,
    overflowScroll: true,
    updateHash: true,
    touchScroll: true,
    before: function (index) {
      anchors.forEach((a) => {
        a.classList.remove("active");
      });
      anchors[index + 1].classList.add("active");
    },
  });
}

function screenCheck() {
  const deviceAgent = navigator.userAgent.toLowerCase();
  const agentID = deviceAgent.match(/(iphone|ipod|ipad)/);
  if (agentID) {
    // it's mobile screen
    $("section").addClass("scroll");
    applyScroll();
    $.scrollify.enable();
  } else {
    // it's desktop screen
    $.scrollify.destroy();
    $("section").removeClass("scroll");

    disapplyScroll();
  }
}

//------------------------------------------------------------------------------------------------
// class active on section when clicking on menu

const anchors = document.querySelectorAll('a[href^="#"]');
const deviceAgent = navigator.userAgent.toLowerCase();
const agentID = deviceAgent.match(/(iphone|ipod|ipad)/);

anchors.forEach((anchor) => {
  anchor.addEventListener("click", function (e) {
    e.preventDefault();

    // Supprimez la classe "active" de tous les éléments ancre
    anchors.forEach((a) => {
      a.classList.remove("active");
    });

    // Ajoutez la classe "active" à l'élément ancre actuel
    this.classList.add("active");

    if (!agentID) {
      // Faites défiler jusqu'à l'élément cible avec un effet de défilement en douceur
      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
    } else {
      document.querySelector(this.getAttribute("href")).scrollIntoView();
    }
  });
});
