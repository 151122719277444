import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="arrowslideformation"
export default class extends Controller {
  connect() {}
}

//----------------- Slider de XP -----------------//

const Xps = document.querySelectorAll(".container-slide-xp");
const nbXP = Xps.length;

const next = document.querySelector(".right-arrow-xp");
const previous = document.querySelector(".left-arrow-xp");

const dots = document.querySelectorAll(".slider-dot");
const nbDots = dots.length;
let counter = 0;

let resizeTimer;
window.addEventListener("resize", () => {
  if (window.innerWidth <= 768) {
    // Limiter aux mobiles
    document.body.classList.add("resize-animation-stopper");
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      document.body.classList.remove("resize-animation-stopper");
    }, 400);
  }
});

function xpSuivant() {
  Xps.forEach((xp) => xp.classList.remove("active"));
  Xps.forEach((xp) => xp.classList.remove("active-left"));
  dots.forEach((dot) => dot.classList.remove("active"));

  if (counter < nbXP - 1) {
    counter++;
    // } else {
    //   counter = 0;
  }

  Xps[counter].classList.add("active");
  dots[counter].classList.add("active");

  // console.log(counter);
}

if (next) {
  next.addEventListener(
    "click",
    function () {
      xpSuivant();
    },
    false
  );
}

function xpPrecedent() {
  Xps.forEach((xp) => xp.classList.remove("active"));
  Xps.forEach((xp) => xp.classList.remove("active-left"));
  dots.forEach((dot) => dot.classList.remove("active"));

  if (counter > 0) {
    counter--;
    // } else {
    //   counter = nbXP - 1;
  }

  Xps[counter].classList.add("active-left");
  dots[counter].classList.add("active");
}

if (previous) {
  previous.addEventListener(
    "click",
    function () {
      xpPrecedent();
    },
    false
  );
}

if (dots) {
  dots.forEach((dot, i) => {
    dot.addEventListener("click", () => {
      Xps.forEach((Xp) => Xp.classList.remove("active"));
      Xps.forEach((xp) => xp.classList.remove("active-left"));
      dots.forEach((dot) => dot.classList.remove("active"));

      counter = i;

      Xps[counter].classList.add("active");
      dots[counter].classList.add("active");
    });
  });
}
