import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fadeout"
export default class extends Controller {
  connect() {
    console.log("hero fade in and out");
  }
}

const sr = ScrollReveal({
  distance: "36px",
  duration: 1000,
  reset: true,
});

sr.reveal(".hero-row", { delay: 250, origin: "top" });
sr.reveal(".img-hero-row ", { delay: 250, origin: "bottom" });
sr.reveal(".text-middle-down", { delay: 175, origin: "bottom" });
sr.reveal(".container-contact", { delay: 175, origin: "top" });

sr.reveal(".img-mobile", { delay: 150, origin: "left" });
sr.reveal(".social_icon", { delay: 400, origin: "right" });

const sr2 = ScrollReveal({
  distance: "0px",
  duration: 1000,
  reset: true,
});

sr2.reveal(".widget1", { delay: 100, origin: "left" });
sr2.reveal(".widget2", { delay: 275, origin: "left" });
sr2.reveal(".widget3", { delay: 400, origin: "left" });
sr2.reveal(".widget4", { delay: 475, origin: "left" });
sr2.reveal(".widget5", { delay: 550, origin: "left" });

const sr3 = ScrollReveal({
  distance: "28px",
  duration: 1000,
  reset: false,
  mobile: false,
});

sr3.reveal(".projet.linkedin", { delay: 300, origin: "bottom" });
sr3.reveal(".projet.img", { delay: 800, origin: "left" });
sr3.reveal(".projet.formation", { delay: 1200, origin: "top" });
sr3.reveal(".projet.xp", { delay: 1700, origin: "right" });
