import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="loaderpage"
export default class extends Controller {
  connect() {}
}

const loader = document.querySelector(".loader-div");

window.addEventListener("load", () => {
  setTimeout(() => {
    loader.classList.add("fondu-out");
  }, 500);
});
